import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { consoleLog } from "../../../utils/log-utils";
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import { STORE_API_PATH } from "../../../network/config/apiPaths";
import { listAction } from "../../../network/store/action/CategoryResponseAction";
import axios from "axios";
import { useToastMsg } from "../../hooks/useToastMsg";
import { BannerImageUplaodRequest } from "../../../network/service/ImageUploadService";

function EditBanner() {


    const navigate = useNavigate();
    
const location=useLocation();
    const [inputValidation, setInputValidation] = useState({
        selectCategory: location.state!=null?location.state.item.option_value:"",
        storeId: location.state!=null?location.state.item.storeId:"",
        bannerName: location.state!=null?location.state.item.name:"",
        city:""
       
      });
      
    
      const [files, setFile] = useState(location.state!=null?location.state.item.image_path:"");
      
  
      const dispatch = useDispatch();
      const [resultList, setResultList] = useState([]);
      const [storeResult, setStoreResult] = useState([]);
      const [availableLocation, setAvailableLocation] = useState([]);
    
      useEffect(() => {
        categoryData(); 
        locationList();
      }, []);
    
      useEffect(() => {
        inputValidation.selectCategory!==""&&
        StoreList()
        
      }, [inputValidation.selectCategory]);
      
    
      const categoryData = async () => {
        await dispatch(
          listAction(
            {
              secId: "",
            },
            dispatch
          )
        ).then((reponse) => {
          if (reponse.result) {
            
            consoleLog("category response...", reponse.result)
            setResultList(reponse.result);
            setInputValidation({
                ...inputValidation,
                storeId: location.state!==null?reponse.result[+(location.state.item.option_value)-1].id:reponse.result[0].id,
              });
          }
        });
      };
      async function StoreList() {
        const data = await axios.get(
          API_CONFIG.API_HOST + STORE_API_PATH.STORE_LIST+`?catId=${inputValidation.selectCategory}&cityName=${inputValidation.city}`,
          {
            headers: API_HEADERS,
          }
        );
       
        consoleLog("store response...", data.data.result);
        setStoreResult(data.data.result);
      }
    
      async function locationList() {
    
        const data = await axios.get(
          API_CONFIG.API_HOST +'Store/availableLocations',
          {
            headers: API_HEADERS,
          }
        );
       
        consoleLog("location response...", data.data.result)
        setAvailableLocation(data.data.result);
        
      }
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValidation({
          ...inputValidation,
          [name]: value,
        });
        
       
      };
      
    
     const reset=()=>{
      setInputValidation({
        selectCategory: "",
        storeId: "",
        bannerName: "",
       
      })
     
     }
    
      const [success,warning]=useToastMsg("Successfully added category banner","Somthing went wrong",reset)
    
      const Validation = () => {
        inputValidation.selectCategory === "" ||
          inputValidation.selectCategory === "--Banner Type--" ||
          inputValidation.bannerName === ""||
          files===""
          ? warning()
          : uploadImage();
      };
    
    
    
      const categoryItems = () => {
        return (
          <>
            {resultList.map((obj) => {
              return (
                <>
                  <option key={obj.id} value={obj.id}>{obj.name}</option>
                </>
              );
            })}
          </>
        );
      };
    
    
    
      const uploadImage = async () => {
        
        
          const selectedIndex = storeResult.findIndex(
            (obj) => obj.id === inputValidation.storeId
          );
        const form_data = new FormData();
        form_data.append("file", files);
        let requestBody = {
          name: inputValidation.bannerName,
          option: inputValidation.selectCategory,
          optionValue: inputValidation.storeId,
          type: "CATEGORY",
          fileData: form_data,
          latitude:storeResult[selectedIndex].latitude,
          longitude: storeResult[selectedIndex].longitude,
          radius:storeResult[selectedIndex].radius,
        };
        console.log(inputValidation);
        console.log(requestBody);
        await dispatch(BannerImageUplaodRequest(requestBody)).then((response) => {
          
          consoleLog("response",response)
          response.data.responseCode===200 && success()    
          
        });
      };
      const handleImageInputChange = (event) => {
        const fileUploaded = event.target.files[0];
        if (fileUploaded != null) {
          setFile(fileUploaded);
          // uploadImage(fileUploaded);
        }
      };
    
      const storeItems = () => {
    
        return (
          <>
          <label className=" mt-4">Store Name</label>
            <select
              value={inputValidation.storeId}
              name="storeId"
              onChange={handleChange}
              className="form-control"
            >
              <option value={""}>{"--select store--"}</option>
              {
               storeResult&&storeResult.length>0&& storeResult.map((obj) => {
                  return(
                   <option value={obj.id} key={obj.id}>{obj.name}</option>
                  )
                })
              }
            </select>
          </>
        );
      };
    
      const locationStores=()=>{
        return (
          <>
          <label className=" mt-4">City</label>
            <select
              value={inputValidation.city}
              name="city"
              onChange={handleChange}
              className="form-control"
            >
              <option value={""}>{"--select City--"}</option>
              {
               availableLocation&&availableLocation.length>0&& availableLocation.map((obj) => {
                  return(
                   <option value={obj.city} key={obj.city}>{obj.city}</option>
                  )
                })
              }
            </select>
          </>
        );
      }
    return (
        <>
            <div
                style={{
                    position: "fixed",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    width: "100%",
                    height: "100%",
                    zIndex: "999",
                    overflow: "scroll",
                }}
            >
                <div className="d-flex align-item-center justify-content-center">
                    <div className="col-md-8">
                        <div className="container">
                            <div className="row mt-2">
                                <div className="col-md-5 card-1 p-5">
                                    <h4 className="text-center-new">Update Banner</h4>
                                    <div className="col-md-12">
                                        <div className="form-group">


                                            <label>Category</label>
                                            <select
                                                className="form-control"
                                                name="selectCategory"
                                                value={inputValidation.selectCategory}
                                                onChange={handleChange}
                                            >
                                                <option>{"--Category--"}</option>
                                                {categoryItems()}
                                            </select>

                                            {locationStores()}

                                            {storeItems()}
                                           
            <label className="mt-4">Banner Name</label>
            <input
              placeholder="Banner Name"
              name="bannerName"
              value={inputValidation.bannerName}
              onChange={handleChange}
              className="form-control"
            />
           
         
            {/* <label className="mt-4">Banner Image</label>
            <input
              type="file"
              name="image"
              value={inputValidation.image}
              onChange={handleImageInputChange}
              className="form-control"
              style={{
                border: "1px solid #80df6d",
                height: 150,
                textDecoration: "underline",
              }}
              placeholder="Browse/Upload"
            />
            {files === "" && (
              <span className="text-danger">*Required</span>
            )} */}
          

                                            

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    marginTop: 20,
                                                }}
                                            >
                                                <button
                                                    style={{ display: "flex", justifyContent: "center" }}
                                                    type="button"
                                                    className="btn btn-purple-bg"
                                                    // onClick={updateUser}
                                                >
                                                    Submit
                                                </button>

                                                <button
                                                    className="btn btn-white-bg"
                                                    onClick={() => {
                                                        // dispatch(
                                                        //   updateFormInfo({
                                                        //     displayForm: false,
                                                        //     formData: {},
                                                        //   })
                                                        // );
                                                        navigate(-1)
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );

}
export default EditBanner