import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { BANNER_API_PATH, CATGORY_API_PATH, STORE_API_PATH, USER_API_PATH } from "../../network/config/apiPaths";
import AutoComplete from "react-google-autocomplete";
import axios from "axios";
import { useToastMsg } from "../hooks/useToastMsg";
import { consoleLog } from "../../utils/log-utils";

function AddStore() {
  const naviagte = useNavigate();
  const [inputValidation, setInputValidation] = useState({
    storeName: "",
    contactName: "",
    contactNo: "",
    selectCategory: "",
    location: "",
    gstNo: "",
    deliveryTime:"",
    assignTo:"",
    fssaiNo: "",
    radius: "",
    fo:"",
    minFree:"",
    delivery_charge:"",
    address:"",
    store_type:""
  });

  const[areaName,setAreaName] = useState("");
  const[cityName,setCity]=useState("");
  const[latitude,setLatitude]=useState(0);
  const[longitude,setLongitude]=useState(0);
  const[categoryList,setCategoryList]=useState([]);
  const [MeList,setMeList]=useState([]);
  const [selectedLocation,setSelectedLocation]=useState(null)
  const [foList,setFoList]=useState([]);


  useEffect(()=>{
    CategoryApi();
    meExcutiveApicall();
  },[])
  useEffect(()=>{
    fetchFoList()
  },[latitude,longitude])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValidation({
      ...inputValidation,
      [name]: value,
    });
  };

  const meExcutiveApicall=async()=>{
    let data=await axios.get(API_CONFIG.API_HOST+USER_API_PATH.LIST_PATH+"3",{
      headers:API_HEADERS
    })
    consoleLog("data",data.data.result)
    setMeList(data.data.result)
  }

  const reset = () =>{
    setInputValidation({
      storeName: "",
    contactName: "",
    contactNo: "",
    selectCategory: "",
    location: "",
    gstNo: "",
    deliveryTime:"",
    assignTo:"",
    fssaiNo: "",
    radius: "",
    });
    setAreaName("");
    setCity("");
   
    setLatitude(0);
    setLongitude(0);
  }

  const[success,warning]=useToastMsg("successfully created","Required all input feilds",reset)

  const Validation = () => {
    inputValidation.storeName === "" ||
    inputValidation.contactName === "" ||
    inputValidation.contactNo === "" ||inputValidation.contactNo.length !==10||
    inputValidation.selectCategory === "" ||inputValidation.delivery_charge===""||inputValidation.deliveryTime===""||
    cityName==="" || areaName===""||
    
    inputValidation.radius === ""
      ? warning()
      : CreateStore();
  };

  async function fetchFoList() {
    if (latitude !== 0 && longitude!== 0) {
      let apiPath =
        API_CONFIG.API_HOST +
        `user/getNearbyUsers?lat=${latitude}&lng=${longitude}&roleId=9`;

      const data = await axios.get(apiPath, {
        headers: API_HEADERS,
      });

      // setIsLoading(false);
      consoleLog("FoResult", data.data.result);

      setFoList(data.data.result);
      // setResultList(data.data.result);
    }
  }

  const CreateStore=async ()=>{
    let response=await fetch(API_CONFIG.API_HOST+STORE_API_PATH.CREATE_STORE,{
      method:"POST",
      headers: API_HEADERS,
      body:JSON.stringify({
        landmark:areaName,
        name:inputValidation.storeName,
        cName:inputValidation.contactName,
       latitude:latitude,
       longitude:longitude,
       radius:inputValidation.radius,
       isVeg:"YES",
       city:cityName,
       fssai:inputValidation.fssaiNo,
       gstNumber:inputValidation.gstNo,
       address:inputValidation.address,
       phoneOne:inputValidation.contactNo,
       sectionId:inputValidation.selectCategory,
       createdBy:inputValidation.assignTo,
       deliveryTime:inputValidation.deliveryTime,
       dCharge:inputValidation.delivery_charge,
       franchiseId:inputValidation.fo,
       free_delivery_min:inputValidation.minFree,
       storeType:inputValidation.store_type,
       id:""

      })
    })
    consoleLog("response",response)
    response.status===200 && success()
   
  }

  const updatePlaceInfo = (place) => {
    if (place && place.address_components != null) {
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        switch (addressType) {
          case "locality":
           setAreaName(place.address_components[i].long_name)
            
            break;
          case "administrative_area_level_3":
          setCity(place.address_components[i].long_name)
            
            
            break;
          // case "country":
          //   setCountry(place.address_components[i].long_name);
          //   break;
        }
      }
    }
    consoleLog("place..", place);
    
    setLatitude(place.geometry.location.lat())
    setLongitude(place.geometry.location.lng())
  };

  const labelStyle = {
    fontWeight: "bold",
  };

  const CategoryApi=async()=>{
    const data=await axios.get(API_CONFIG.API_HOST+CATGORY_API_PATH.LIST_PATH,{
      headers:API_HEADERS
    })
    consoleLog("data",data.data.result)
    setCategoryList(data.data.result)
}
 
const categoryItems =()=>{
  return(
      categoryList.map((item,i)=>{
        return(
          <option value={item.id} key={item.id}>{item.name}</option>
        )
      })
  )
   
}

  return (
    <>
    {consoleLog("selected location",selectedLocation)}
      <ToastContainer />
      <main className="container-fluid dashboard">
        <div className="row m-3">
          <div className="col-12">
          <h3>Add New Store</h3>
          </div>
          
            <div className="row mt-2">
              <div className="col-12 col-md-6 col-lg-3">
                <label>Category</label>
                <select
                  className="form-control store-margin"
                  name="selectCategory"
                  value={inputValidation.selectCategory}
                  onChange={handleChange}
                >
                  <option>{"--select category--"}</option>
                  {categoryItems()}
                </select>

                {inputValidation.selectCategory === "" && (
                  <span className="text-danger">*Required</span>
                )}
              </div>

              <div className="col-12 col-md-6 col-lg-3 store-margin">
              <label>Store Name</label>
                <input
                  type="text"
                  name="storeName"
                  value={inputValidation.storeName}
                  onChange={handleChange}
                  class="form-control"
                  placeholder="Store Name"
                ></input>

                {inputValidation.storeName === "" && (
                  <span className="text-danger">*Required</span>
                )}
              </div>

              <div className="col-12 col-md-6 col-lg-3 store-margin">
              <label>Contact Name</label>
                <input
                  type="text"
                  name="contactName"
                  value={inputValidation.contactName}
                  onChange={handleChange}
                  class="form-control"
                  placeholder="Contact Name"
                ></input>

                {inputValidation.contactName === "" && (
                  <span className="text-danger">*Required</span>
                )}
              </div>

              <div className="col-12 col-md-6 col-lg-3 store-margin">
              <label>Contact No</label>
                <input
                  type="number"
                  name="contactNo"
                  value={inputValidation.contactNo}
                  onChange={handleChange}
                  class="form-control"
                  placeholder="Contact  No"
                ></input>

                {inputValidation.contactNo.length ===10 ?<span></span>:<span className="text-danger">*Enter 10 digit number</span>}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-12 col-md-6 col-lg-3">
              <label style={labelStyle}>Search City/Town</label>{" "}
              {inputValidation.place_address}
              <AutoComplete
                className="form-control"
                // value={selectedLocation !== "" ? selectedLocation : ""}
                style={{ width: "100%" }}
                name="landmark"
               options={{
                      types: ["geocode", "establishment"],
                    }}
                onChange={(e) => handleChange(e)}
                apiKey={"AIzaSyCB14naEHjDiVBFLEqhPBMUA9eqg_4yRdk"}
                onPlaceSelected={(place) => updatePlaceInfo(place)}
                placeholder="Enter city name"
              />
              {(areaName===""||cityName==="")&&<span className="text-danger">*Required</span>}
              </div>

              <div className="col-12 col-md-6 col-lg-3">
                <label>GST No</label>
                <input
                  type="text"
                  name="gstNo"
                  value={inputValidation.gstNo}
                  onChange={handleChange}
                  class="form-control"
                  placeholder="GST No"
                ></input>

                
              </div>

              <div className="col-12 col-md-6 col-lg-3">
              <label>Fssai No</label>
                <input
                  type="text"
                  name="fssaiNo"
                  value={inputValidation.fssaiNo}
                  onChange={handleChange}
                  class="form-control"
                  placeholder="Fssai No"
                ></input>

                
              </div>

              <div className="col-12 col-md-6 col-lg-3">
              <label>Radius</label>
                <input
                  type="number"
                  name="radius"
                  value={inputValidation.radius}
                  onChange={handleChange}
                  class="form-control"
                  placeholder="Radius"
                ></input>

                {inputValidation.radius === "" && (
                  <span className="text-danger">*Required</span>
                )}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 col-md-6 col-lg-3">
                <label>Delivery Time</label>
                <input 
                type="text" 
                onChange={handleChange} 
                placeholder="Delivery Time" 
                name="deliveryTime" 
                value={inputValidation.deliveryTime} 
                className="form-control"
                />
                {inputValidation.deliveryTime === "" && (
                  <span className="text-danger">*Required</span>
                )}
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <label>Assign To Marketing Executive</label>
                <select 
               
                onChange={handleChange} 
               
                name="assignTo" 
                value={inputValidation.assignTo} 
                className="form-control"
                >
                  <option value="">{"--Assign To Marketing Executive--"}</option>
                  {
                    MeList?MeList.length>0&&MeList.map(item=>{
                      return(
                        <option value={item.id}>{item.name}</option>
                      )
                    }):<>No data found</>
                  }
                </select>
              </div>

              <div className="col-12 col-md-6 col-lg-3">
                <label>Assign To FO</label>
                <select 
               
                onChange={handleChange} 
               
                name="fo" 
                value={inputValidation.fo} 
                className="form-control"
                >
                  <option value="">{"--Assign To Franchise Owner--"}</option>
                  {
                    foList?foList.length>0&&foList.map(item=>{
                      return(
                        <option value={item.id}>{item.name}</option>
                      )
                    }):<>No data found</>
                  }
                </select>
              </div>

              <div className="col-lg-3">
                <label>Free min order</label>
                <input
                  type="number"
                  name="minFree"
                  value={inputValidation.minFree}
                  onChange={handleChange}
                  class="form-control"
                  placeholder="free min order"
                ></input>
            </div>
             <div className="row mt-3">
             <div className="col-lg-3 mt-3">
            <label>Delivery Charge</label>
                <input
                  type="text"
                  name="delivery_charge"
                  value={inputValidation.delivery_charge}
                  onChange={handleChange}
                  class="form-control"
                  placeholder="Delivery Time"
                ></input>
                {inputValidation.delivery_charge === "" && (
                  <span className="text-danger">*Required</span>
                )}
            </div>
            <div className="col-lg-3 mt-3">
              <label>Address</label>
              <input
              className="form-control"
                name="address"
                value={inputValidation.address}
                onChange={handleChange}
                type="text"
              />
            </div>
            <div className="col-lg-3 mt-3">
              <label>Store Type</label>
              <select
              className="form-control"
                name="store_type"
                value={inputValidation.store_type}
                onChange={handleChange}
                type="text">
                  <option value="NEAR_BY">NEAR_BY</option>
                  <option value="ONLINE">ONLINE</option>
                </select>
            </div>
             </div>

            </div>
            <div class="mr-auto col-12 text-end mt-3">
              <button
                type="button"
                class="btn btn-danger me-2"
                onClick={() => naviagte(-1)}
              >
                Cancel
              </button>
              <button
                type="button"
                class="mr-auto btn btn-purple-bg me-2"
                onClick={Validation}
              >
                Save
              </button>
              {consoleLog("final input", inputValidation)}
            </div>
          
        </div>
      </main>
    </>
  );
}
export default AddStore;
