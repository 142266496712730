import { useLocation, useNavigate } from "react-router-dom";
import UniqueStoreItem from "./unique-store-item";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import UniqueStoreProducts from "./unique store/uniqueStore-products";
import UniqueStoreOrders from "./unique store/uniqueStore-orders";
import UniqueStoreSettlements from "./unique store/uniqueStore-payments";
import { useSelector } from "react-redux";
import { useState } from "react";
import { consoleLog } from "../../utils/log-utils";
import OrderWisePayments from "./unique store/unique store items/order-wise-payments";

function UniqueStore() {
  const navigate = useNavigate();
  const location = useLocation();
  const storeId = location.state != null ? location.state.storeId : "";
  const storeName = location.state != null ? location.state.storeName : "";
  const storeCity = location.state != null ? location.state.storeCity : "";

  const event=location.state != null?location.state.event:""
  const storeAddress =
    location.state != null ? location.state.storeAddress : "";
    consoleLog("....",storeId)
  return (
    <>
      <main className="container-fluid dashboard">
      <div className="row m-3 mt-0 d-flex flex-row">
          <div className="col-12 ">
            <h3 className="dashboard-title">{storeName}</h3>
            <h6>{storeCity}</h6>
            <h6>{storeAddress}</h6>
          </div>
          <div className="align-middle">
        
          {/* <div class="form-check form-switch col-lg-12 d-flex justify-content-end">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
             
              
            />
          </div> */}

       
          </div>
          
          
        </div>
        <div className="row m-3">
          <div className="col-12 mt-4">
            <Tabs
              defaultActiveKey={event}
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="products" title="Products">
                <UniqueStoreProducts id={storeId}/>
              </Tab>
              <Tab eventKey="orders" title="Orders">
                <UniqueStoreOrders id={storeId} />
              </Tab>
              <Tab eventKey="orderWisePayments" title="Payments">
                <OrderWisePayments id={storeId} />
              </Tab>
              <Tab eventKey="payments" title="Settlements">
                <UniqueStoreSettlements id={storeId} />
              </Tab>
            </Tabs>
          </div>
          </div>
      
       
        
      </main>
    </>
  );
}

export default UniqueStore;
