import { useNavigate } from "react-router-dom";
import BannerItem from "../home/banner-item";
import LocationItem from "./location-item";
import axios from "axios";
import { BANNER_API_PATH } from "../../../network/config/apiPaths";
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import { useEffect, useState } from "react";
import DeleteBanner from "../crudOperations/deleteBanner";
import { useDispatch, useSelector } from "react-redux";
import { deleteStatus } from "../../../redux/slice/formUpdateSlice";
import { updateMessage } from "../../../redux/slice/toastSlice";
import { consoleLog } from "../../../utils/log-utils";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';


function LocationBanners() {
  const navigate=useNavigate();
  const dispatch=useDispatch();
  const [deleteBanner, setDeleteBanner] = useState(false);
  const[locationList,setLocationList]=useState([]);
  const showDeleteAlert=useSelector(deleteStatus);
  const [isLoading,setIsLoading]=useState(false);
  useEffect(()=>{
    LocationApi()
  },[])
  
  const LocationApi=async()=>{
    setIsLoading(true);
      const data=await axios.get(API_CONFIG.API_HOST+BANNER_API_PATH.LIST_PATH+"LOCATION",{
        headers:API_HEADERS
      })
      setIsLoading(false)
      
      consoleLog("data",data.data.result)
      if(data.data.result !== null){
       
      setLocationList(data.data.result)
      }
  }
  const onSubmit = () => {
    
    dispatch(
      updateMessage({
        display: true,
        message: "Record deleted",
      })
    );
    // setBannerList([]);
    LocationApi()

    // loadData();
  };

  const ItemType = 'ROW';

  const DraggableRow = ({ row, index, moveRow }) => {
    const [, drag] = useDrag({
      type: ItemType,
      item: { index },
    });
  
    const [, drop] = useDrop({
      accept: ItemType,
      hover: (item) => {
        if (item.index !== index) {
          moveRow(item.index, index); // Move the row when hovered
          item.index = index; // Update the dragged item's index
        }
      },
    });
  
    return (
      
      <tr ref={(node) => drag(drop(node))} style={{ cursor: 'move' }}>
         <LocationItem
                          result={row}
                          key={row.id}
                          id={index + 1}
                        />
      </tr>
    );
  };
  
  const DragAndDropTable = (props) => {
    const [rows, setRows] = useState(props.list);
  
    const moveRow = (fromIndex, toIndex) => {
      const updatedRows = [...rows];
      const [movedRow] = updatedRows.splice(fromIndex, 1);
      updatedRows.splice(toIndex, 0, movedRow);
      setRows(updatedRows);
    };
    return (
      <table className="table table-hover align-middle">
                <thead className="thead-dark">
                <tr>
                  <th scope="col">Sno</th>
                  
                  <th scope="col">Area Name</th>
                  <th scope="col">City</th>
                  <th scope="col">Banner Name</th>
                  
                  <th scope="col">Banner Image</th>
                  {/* <th scope="col">Status</th> */}
                  <th scope="col">Actions</th>
                </tr>
  
                </thead>
        <tbody>
          {
            isLoading ? (
              <>Loading ..</>
            ) : rows && rows.length > 0 ? (
              
          rows.map((row, index) => (
            <DraggableRow key={row.id} index={index} row={row} moveRow={moveRow} />
          )))
          : (
            <> No Data</>
          )
          }
        </tbody>
      </table>
    );
  };
 

  return (
    <>
    {showDeleteAlert && (
        <DeleteBanner 
          onSubmit={() => {
            onSubmit();
          }}
        />
      )}
      <main className="container-fluid dashboard">
        <div className="row m-3">
          <div className="row justify-content-around tble">
            <div className="col-6">
              <h3 className="dashboard-title">Location Banners</h3>
            </div>
            <div className="mr-auto col-6 text-end">
              <button
                type="button"
                className="mr-auto btn add-button-color"
                onClick={() => navigate("/addNewLocation")}
              >
                Add New
              </button>
            </div>
          </div>
        </div>
        <div className="row m-3">
          <div className="col-md-12 card-1 h-scroll">
            {/* <table className="table table-hover align-middle">
              <thead className="thead-dark">
               
              </thead>

              <tbody>
                {isLoading?(
                  <>Loading...</>
                ):
                locationList && locationList.length > 0 ? (
                  <>
                    {locationList.map((item, index) => {
                      return (
                        <LocationItem
                          result={item}
                          key={item.id}
                          id={index + 1}
                        />
                      );
                    })}
                  </>
                ) :(
                    <>No data</>
                )
                  
                }
               
              </tbody>
            </table> */}
            <DndProvider backend={HTML5Backend}>
               <DragAndDropTable list={locationList}/>
             </DndProvider>
          
          </div>
        </div>
      </main>
    </>
  );
}

export default LocationBanners;
