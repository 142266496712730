import {
  FaEdit,
  FaEye,
  FaRegEdit,
  FaStar,
  FaTag,
  FaTrash,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import {
  displayRemoveAlert,
  updateFormInfo,
} from "../../redux/slice/formUpdateSlice";
import { useDispatch } from "react-redux";
import { useRef, useState } from "react";
import {
  isCustomerSupport,
  isDsplayCrud,
  isEmployee,
  isFranchisLogin,
} from "../../utils/userRoles";
import { axiosApi } from "../../network/service/config/AaxiosUtil";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { STORE_API_PATH } from "../../network/config/apiPaths";
import { updateMessage } from "../../redux/slice/toastSlice";
import { consoleLog } from "../../utils/log-utils";
import noImage from "../../assets/no_image.jpg";

import { getImagePath } from "../../utils/util";
import { storeImageUplaodRequest } from "../../network/service/ImageUploadService";

function StoreItem(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [product, setItem] = useState(props.item);
  const [itemStatus, setitemStatus] = useState(product.status);
  const hiddenFileInput = useRef(null);
  const [file, setFile] = useState();
  consoleLog("master store", product);

  const editItem = () => {
    // dispatch(updateFormInfo({ displayForm: false, formData: { product } }));
    navigate("/updateStore", { state: { product } });
  };

  const editTags = () => {
    dispatch(updateFormInfo({ displayForm: false, formData: { product } }));
    navigate("/stags");
  };

  const onImageClick = () => {
    dispatch(updateFormInfo({ imageUpload: true, formData: { product } }));
  };

  const listItem = () => {
    // dispatch(updateFormInfo({ displayForm: false, formData: { product } }));
    navigate("/uniqueStore", {
      state: {
        storeId: product.id,
        storeName: product.name,
        storeCity:product.city,
        storeAddress: product.address,
      },
    });
  };

  const onStatusChange = async () => {
    let status = "YES";
    if (product.status == "NO") {
      status = "YES";
    } else {
      status = "NO";
    }

    let requestBody = {
      status: status,
      id: product.id,
    };

    await axiosApi
      .post(API_CONFIG.API_HOST + STORE_API_PATH.CREATE_STORE, requestBody, {
        API_HEADERS,
      })
      .then((response) => {
        consoleLog("STORE UPODATE", response);
        if (response.status === 200 && response.data.status === true) {
          setItem(product, (product.status = status));
          setitemStatus(status);
        } else {
          dispatch(
            updateMessage({
              display: true,
              message: "Error While updating info",
            })
          );
        }
      });
  };

  const deleteItem = () => {
    dispatch(
      displayRemoveAlert({
        displayRemove: true,
        formData: { item: product },
        type: "store",
      })
    );
  };

  const handleImageInputChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded != null) {
      setFile(URL.createObjectURL(fileUploaded));
      console.log("file upload", fileUploaded);
      uploadImage(fileUploaded);
    }
  };

  const uploadImage = async (file) => {
    const form_data = new FormData();
    form_data.append("file", file);
    let requestBody = {
      id: product.id,
      fileData: form_data,
    };
    console.log("requestbody", requestBody);
    await dispatch(storeImageUplaodRequest(requestBody)).then((response) => {
      consoleLog(response);
    });
  };
  const onNgImageClick = () => {
    hiddenFileInput.current.click();
  };
  return (
    <>
      <tr>
        <td>{product.id}</td>
        {/* <td>{product.is_veg === "NO" ? "Non-veg" : "Veg"}</td> */}
        <td>{product.categoryName}</td>
        <td onClick={() => listItem()}>
          <Link className="anchor-color-change"> {product.name}</Link>
        </td>

        <td className="align-middle">
          <div className="cat_mainwrap" onClick={onNgImageClick}>
            {product.image_path === null ? null : (
              <div className="cat_mainwrap">
                {product.image_path === "" ? null : (
                  <div className="cat_btnwrap">
                    {/* <FaRegEdit className="faWhite" onClick={() => onNgImageClick()} />

              <FaEye className="faWhite" onClick={() => showLargeImage()} /> */}
                  </div>
                )}

                <input
                  type="file"
                  onChange={handleImageInputChange}
                  ref={hiddenFileInput}
                  style={{ display: "none" }} // Make the file input element invisible
                />
                {file ? (
                  <>
                    <img
                      src={file}
                      alt={product.name}
                      className="img-thumbnail"
                      width="50px"
                      height="50px"
                    />
                  </>
                ) : product.image_path !== "" ? (
                  <img
                    src={getImagePath(product.image_path)}
                    alt={product.name}
                    className="img-thumbnail"
                    width="50px"
                    height="50px"
                  />
                ) : (
                  <img
                    src={noImage}
                    alt={product.name}
                    className="img-thumbnail"
                    width="50px"
                    height="50px"
                  />
                )}
              </div>
            )}
          </div>
        </td>

        <td>{product.location_name}</td>
        <td>{product.city}</td>
        <td>{product.contact_name}</td>
        <td>
          {product.contact_one}
          <br />
          {product.contact_two}
        </td>
        {!isFranchisLogin() && <td>{product.frName}</td>}
        <td>{product.radius}</td>
        <td>
          <FaStar style={{ color: "yellow" }} /> {product.avg_rating}
        </td>
        {!isFranchisLogin() && !isCustomerSupport() && (
          <>
            <td className="align-middle">
              <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  onChange={onStatusChange}
                  checked={itemStatus === "YES" ? true : false}
                />
              </div>
            </td>
          </>
        )}

        {isDsplayCrud() && (
          <>
            <td className="align-middle">
              <div className="d-flex justify-content-center">
                <span className="edit-icon-btn" onClick={() => editItem()}>
                  <FaEdit />
                </span>
                {/* <span className="delete-icon-btn" onClick={deleteItem}>
                  <FaTrash />
                </span> */}
                <span
                  className="delete-icon-btn"
                  onClick={() => {
                    editTags();
                  }}
                >
                  <FaTag />
                </span>
              </div>
            </td>
          </>
        )}
      </tr>
    </>
  );
}

export default StoreItem;
