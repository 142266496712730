import { FaEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { displayRemoveAlert } from "../../../redux/slice/formUpdateSlice";
import { useState } from "react";
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import { USER_API_PATH } from "../../../network/config/apiPaths";
import { consoleLog } from "../../../utils/log-utils";
import { axiosApi } from "../../../network/service/config/AaxiosUtil";
import { updateMessage } from "../../../redux/slice/toastSlice";

function FranchiseItem(props) {
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const [product,setItem]=useState(props.item)
  const [itemStatus, setitemStatus] = useState(product.status);
   const onStatusChange = async () => {
      let status = "ACTIVE";
      if (product.status == "BLOCKED") {
        status = "ACTIVE";
      } else {
        status = "BLOCKED";
      }
  
      let requestBody = {
        status: status,
        id: product.id,
      };
  
      await axiosApi
        .put(API_CONFIG.API_HOST + USER_API_PATH.UPDATE_USER, requestBody, {
          API_HEADERS,
        })
        .then((response) => {
          consoleLog("USER UPODATE", response);
          if (response.status === 200 && response.data.status === true) {
            consoleLog("status",response)
            setItem(product, (product.status = status));
            setitemStatus(status);
          } else {
            dispatch(
              updateMessage({
                display: true,
                message: "Error While updating info",
              })
            );
          }
        });
  
     
    };
  const deleteItem = () =>{
    dispatch(displayRemoveAlert({ displayRemove: true, formData: { item:product },type:"users" }));
  }

  const editItem=()=>{
    navigate("/userEdit",{state:{item:product}})
  }

  const franchiseDetail=()=>{
      navigate("/uniqueFranchise",{
        state:{
        name:product,
        event:"foStores",
        // storeName:product.orgName,
        // phone:product.phone,
        // status:product.status
      }
    })
  }
  return (
    <>
      <tr>
        <td> {product.id} </td>
        <td onClick={franchiseDetail}>
          <Link className="anchor-color-change">{product.name}</Link>
        </td>
        <td> {product.phone} </td>
        <td>{product.city}</td>
        <td>{product.radius}</td>
        <td>{product.fo_share}</td>
        
        {/* <td className="align-middle">
            <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
                <input
                    class="form-check-input"
                    type="checkbox"s
                    role="switch"
                    checked={product.status === "ACTIVE" ? true : false}

                />
            </div>
        </td> */}
         <td className="align-middle">
          <div class="form-check form-switch col-lg-12 d-flex justify-content-center">

            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              onChange={onStatusChange}
              checked={itemStatus === "ACTIVE" ? true : false}

            />

          </div>
        </td>
        
        <td className="align-middle">
                    <div className="d-flex justify-content-center">
                    <span className="edit-icon-btn"
                    onClick={editItem}
                    >
                        <FaEdit/>
                    </span>
                    {/* <span className="delete-icon-btn" onClick={deleteItem}>
                        <FaTrash/>
                        </span> */}
                    </div>
                </td>
      </tr>
    </>
  );
}

export default FranchiseItem;
