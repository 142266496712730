import { useEffect, useState } from "react";
import StoreItem from "./store-item";
import axios from "axios";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import {
  CATGORY_API_PATH,
  LOCATION_API_PATH,
  STORE_API_PATH,
} from "../../network/config/apiPaths";
import { useNavigate } from "react-router-dom";
import DeleteBanner from "../banners/crudOperations/deleteBanner";
import { updateMessage } from "../../redux/slice/toastSlice";
import { useDispatch, useSelector } from "react-redux";
import { deleteStatus } from "../../redux/slice/formUpdateSlice";
import {
  isAdmin,
  isCustomerSupport,
  isDsplayCrud,
  isEmployee,
  isFranchisLogin,
} from "../../utils/userRoles";
import { Search } from "semantic-ui-react";
import { consoleLog } from "../../utils/log-utils";

function Stores() {
  const [storeResult, setStoreResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const showDeleteAlert = useSelector(deleteStatus);
  const [categoryResult, setCategoryResult] = useState([]);
  const dispatch = useDispatch();
  const [categoryId, setCategoryId] = useState("");
  const [resultList, setResultList] = useState([]);
  const [storeName, setStoreName] = useState("");
  const [locationName, setLocationName] = useState("");
  const [filterAreaName, setFilterAreaName] = useState("");
  const [areaNameList, setAreaNameList] = useState([]);
  //pagination
  // const [totalCount, setTotalCount] = useState(0);
  // const [pageLimit, setPageLimit] = useState(20);
  // const [pageOffset, setPageOffset] = useState(0);
  // const [activePage, setActivePage] = useState(1);
  // const [pageCount, setPageCount] = useState(0);

  // const lastIndex=activePage*pageLimit;
  // const firstIndex=lastIndex-pageLimit;
  // const records=resultList.slice(firstIndex,lastIndex);
  // const npage=resultList && resultList.length>=0 && Math.ceil(resultList/pageLimit)
  // const numbers=[...Array(npage+1).keys()].slice(1)

  const navigate = useNavigate();
  useEffect(() => {
    categoryApi();
    fetchLocations();
  }, []);
  useEffect(() => {
    StoreList();
  }, [categoryId, filterAreaName]);

  async function StoreList() {
    setIsLoading(true);

    let apiPath =
      API_CONFIG.API_HOST +
      STORE_API_PATH.STORE_LIST +
      `?catId=${categoryId}&area=${filterAreaName}`;
    if (isFranchisLogin()) {
      apiPath =
        API_CONFIG.API_HOST +
        STORE_API_PATH.STORE_LIST +
        "?frId=" +
        localStorage.getItem("userId") +
        `&catId=${categoryId}&area=${filterAreaName}`;
      consoleLog("id", localStorage.getItem("userId"));
    }

    const data = await axios.get(apiPath, {
      headers: API_HEADERS,
    });

    setIsLoading(false);
    consoleLog("storeResult", data.data.result);

    setStoreResult(data.data.result);
    setResultList(data.data.result);
  }

  const categoryApi = async () => {
    let data = await axios.get(
      API_CONFIG.API_HOST + CATGORY_API_PATH.LIST_PATH,
      {
        headers: API_HEADERS,
      }
    );

    setCategoryResult(data.data.result);
  };

  // const storeNameHandleChange = (e) => {
  //   setStoreName(e.target.value);
  //   let searchterm = e.target.value;

  //   consoleLog("category", categoryId);

  //   if (searchterm !== "" && categoryId !== "") {
  //     let data = resultList.filter((item) => {
  //       if (item.name.toLowerCase().includes(searchterm.toLowerCase())) {
  //         return item;
  //       }
  //     });
  //     setResultList(data);
  //   } else if (categoryId === "" && searchterm !== "") {
  //     let data = storeResult.filter((item) => {
  //       if (item.name.toLowerCase().includes(searchterm.toLowerCase())) {
  //         return item;
  //       }
  //     });
  //     setResultList(data);
  //   } else {
  //     setResultList(storeResult);
  //   }
  // };

  const locationNameHandleChange = (e) => {
    let locationterm = e.target.value;
    setLocationName(e.target.value);
    consoleLog("location term", locationterm);
    if (categoryId === "" && locationterm !== "") {
      let data = storeResult.filter((item) => {
        if (
          item.location_name.toLowerCase().includes(locationterm.toLowerCase())
        ) {
          return item;
        }
      });
      setResultList(data);
    } else if (categoryId !== "" && locationterm !== "") {
      let data = resultList.filter((item) => {
        if (
          item.location_name.toLowerCase().includes(locationterm.toLowerCase())
        ) {
          return item;
        }
      });
      setResultList(data);
    } else {
      setResultList(storeResult);
    }
  };

  const fetchLocations = async () => {
    let path = !isFranchisLogin()
      ? LOCATION_API_PATH.LOCATION_LIST_PATH
      : LOCATION_API_PATH.LOCATION_LIST_PATH +
        `foId=${localStorage.getItem("userId")}`;
    let data = await axios.get(API_CONFIG.API_HOST + path, {
      headers: API_HEADERS,
    });
    consoleLog("apiresult", data);
    setAreaNameList(data.data.result);
  };

  const categoryList = () => {
    return (
      <>
        {categoryResult && categoryResult.length > 0 && (
          <>
            {categoryResult.map((obj, i) => {
              return (
                <option value={obj.id} key={obj}>
                  {obj.name}
                </option>
              );
            })}
          </>
        )}
      </>
    );
  };
  const LocationList = () => {
    consoleLog("locationList", areaNameList);
    return (
      <>
        {areaNameList && areaNameList.length > 0 && (
          <>
            {areaNameList.map((obj, i) => {
              return (
                <option value={obj.location_name} key={obj}>
                  {obj.location_name}
                </option>
              );
            })}
          </>
        )}
      </>
    );
  };

  const onSubmit = () => {
    dispatch(
      updateMessage({
        display: true,
        message: "Record deleted",
      })
    );
    // setBannerList([]);
    StoreList();

    // loadData();
  };

  {
  }

  return (
    <>
      {showDeleteAlert && (
        <DeleteBanner
          onSubmit={() => {
            onSubmit();
          }}
        />
      )}
      <main className="container-fluid dashboard">
        {/* <div className="row store-div-margin">
          
          <div className="col-12 col-md-5 store-margin">
            <input
              value={locationName}
              placeholder="Search Location"
              onChange={locationNameHandleChange}
              className="form-control"
            />
          </div>
          <div className="col-12 col-md-5 store-margin ">
            <input
              value={storeName}
              placeholder="Search by Area"
              onChange={storeNameHandleChange}
              className="form-control"
            />
          </div>

        </div> */}
        <div className="row m-2">
          <div className="col-6">
            <h3 className="dashboard-title">Store</h3>
          </div>
          <div className="mr-auto col-6 text-end">
            {!isFranchisLogin() && !isCustomerSupport() && (
              <button
                type="button"
                className="mr-auto btn add-button-color"
                onClick={() => navigate("/addStore")}
              >
                Add New
              </button>
            )}
          </div>
        </div>

        <div className="row store-div-margin">
          <div className="col-12 col-md-4 store-margin">
            <select
              value={categoryId}
              onChange={(e) => {
                setCategoryId(e.target.value);
              }}
              className="form-control"
            >
              <option>{"--Select Category--"}</option>
              {categoryList()}
            </select>
          </div>
          <div className="col-12 col-md-4">
            <select
              value={filterAreaName}
              onChange={(e) => {
                setFilterAreaName(e.target.value);
              }}
              className="form-control"
            >
              <option value="">{"--Select Area Name--"}</option>
              {LocationList()}
            </select>
          </div>
          {/* <div className="col-12 col-md-5 store-margin">
            <input
              value={storeName}
              placeholder="Search by Store Name"
              onChange={storeNameHandleChange}
              className="form-control"
            />
          </div> */}
        </div>

        <div className="row m-3">
          <div className="col-12 card-1 h-scroll">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Store ID</th>
                  <th Scope="col">Store Category</th>
                  <th scope="col">Store Name</th>
                  <th scope="col">Store Logo</th>
                  <th scope="col">Area Name</th>
                  <th scope="col">City</th>
                  <th scope="col">Contact Name</th>

                  <th scope="col">Contact No</th>
                  {!isFranchisLogin() && <th scope="col">FO</th>}

                  <th scope="col">Radius</th>
                  <th scope="col">Rating</th>
                  {!isFranchisLogin() && !isCustomerSupport() && (
                    <>
                      <th scope="col">Status</th>
                    </>
                  )}

                  {isDsplayCrud() && <th scope="col">Actions</th>}
                </tr>
              </thead>

              <tbody>
                {isLoading ? (
                  <>Loading</>
                ) : storeResult !== null ? (
                  storeResult && storeResult.length >= 0 ? (
                    <>
                      {storeResult.map((obj, i) => {
                        
                        return (
                          
                          <StoreItem item={obj} key={obj.id} sno={i + 1} />
                        );
                      })}
                    </>
                  ) : (
                    <>No data</>
                  )
                ) : (
                  <>No Data</>
                )}
              </tbody>
            </table>
          </div>
          {/* <div className="col-12">
            <ul className="pagination">
              {
                numbers.map((n,i)=>{
                  <li className="page-item" key={i}>
                    <a href='#' className="page-link" onClick={changeCpage}>{n}</a>
                  </li>
                })
              }
            </ul>
          </div> */}
        </div>
      </main>
      {consoleLog("store", storeResult)}
      {consoleLog("result", resultList)}
    </>
  );
  //   const changeCpage=()=>{

  //   }
}

export default Stores;
