import { FaEdit, FaTrash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import { USER_API_PATH } from "../../../network/config/apiPaths";
import { updateMessage } from "../../../redux/slice/toastSlice";
import { useDispatch } from "react-redux";
import { consoleLog } from "../../../utils/log-utils";
import { axiosApi } from "../../../network/service/config/AaxiosUtil";
import { useState } from "react";
import { isAdmin, isFranchisLogin } from "../../../utils/userRoles";

function CustomerItem(props) {
  const navigate=useNavigate();
  const dispatch=useDispatch();
  const [product, setItem] = useState(props.item);
  consoleLog("product",product)
  const [itemStatus, setitemStatus] = useState(product.status);
  const customerOrders=()=>{
     navigate("/customerinfo",{state:{id:product.id,name:product.name,phone:product.phone,city:product.city}})
  }
  const onStatusChange = async () => {
    let status = "ACTIVE";
    if (product.status == "BLOCKED") {
      status = "ACTIVE";
    } else {
      status = "BLOCKED";
    }

    let requestBody = {
      status: status,
      id: product.id,
    };

    await axiosApi
      .put(API_CONFIG.API_HOST + USER_API_PATH.UPDATE_USER, requestBody, {
        API_HEADERS,
      })
      .then((response) => {
        consoleLog("USER UPODATE", response);
        if (response.status === 200 && response.data.status === true) {
          consoleLog("status",response)
          setItem(product, (product.status = status));
          setitemStatus(status);
        } else {
          dispatch(
            updateMessage({
              display: true,
              message: "Error While updating info",
            })
          );
        }
      });

    // await dispatch(createUpdateAction(requestBody)).then((response) => {
    //   if (response.status == true) {
    //     setItem(product, (product.status = status));
    //     setitemStatus(status);
    //   } else {
    //     dispatch(
    //       updateMessage({
    //         display: true,
    //         message: "Error While updating info",
    //       })
    //     );
    //   }
    // });
  };
 
  return (
    <>
      <tr>
        <td> {product.id} </td>
        <td onClick={customerOrders}>
          <Link className="anchor-color-change">{product.name}</Link>
        </td>
        {
          isFranchisLogin()? "":<td> {product.phone} </td>
        }
       
        {/* <td> {props.city} </td> */}
        <td> {product.referralcode===""?"Referal":"Direct"} </td>
        <td>{product.os_type}</td>
       

        <td className="align-middle">
          <div class="form-check form-switch col-lg-12 d-flex justify-content-center">

            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              onChange={onStatusChange}
              checked={itemStatus === "ACTIVE" ? true : false}

            />

          </div>
        </td>
      </tr>
    </>
  );
}

export default CustomerItem;
