import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { listAction } from "../../../network/store/action/CategoryResponseAction";
import { useEffect } from "react";
import axios from "axios";
import { STORE_API_PATH } from "../../../network/config/apiPaths";
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import Select from 'react-select';
import { useLocation, useNavigate } from "react-router-dom";
import { BannerImageUplaodRequest } from "../../../network/service/ImageUploadService";
import { useToastMsg } from "../../hooks/useToastMsg";
import { consoleLog } from "../../../utils/log-utils";

function AddCategoryBanner() {
  const navigate = useNavigate();
  const location=useLocation();
  
  const [inputValidation, setInputValidation] = useState({
    selectCategory: location.state!=null?location.state.item.option_value:"",
    storeId: location.state!=null?location.state.item.storeId:"",
    bannerName: location.state!=null?location.state.item.name:"",
    city:""
   
  });
  

  const [files, setFile] = useState(location.state!=null?location.state.item.image_path:"");
  
  const dispatch = useDispatch();
  const [resultList, setResultList] = useState([]);
  const [storeResult, setStoreResult] = useState([]);
  const [availableLocation, setAvailableLocation] = useState([]);

  useEffect(() => {
    categoryData(); 
    locationList();
  }, []);

  useEffect(() => {
    inputValidation.selectCategory!==""&&
    StoreList()
    
  }, [inputValidation.selectCategory]);
  

  const categoryData = async () => {
    await dispatch(
      listAction(
        {
          secId: "",
        },
        dispatch
      )
    ).then((reponse) => {
      if (reponse.result) {
        
        consoleLog("category...", reponse.result[location.state!=null?location.state.item.option_value:0])
        setResultList(reponse.result);
        setInputValidation({
          ...inputValidation,
          storeId: location.state!==null?reponse.result[+(location.state.item.option_value)-1].id:reponse.result[0].id,
        });
      }
    });
  };
  async function StoreList() {
    const data = await axios.get(
      API_CONFIG.API_HOST + STORE_API_PATH.STORE_LIST+`?catId=${inputValidation.selectCategory}&cityName=${inputValidation.city}`,
      {
        headers: API_HEADERS,
      }
    );
   
    consoleLog("store response...", data.data.result);
    setStoreResult(data.data.result);
  }

  async function locationList() {

    const data = await axios.get(
      API_CONFIG.API_HOST +'Store/availableLocations',
      {
        headers: API_HEADERS,
      }
    );
   
    consoleLog("location response...", data.data.result)
    setAvailableLocation(data.data.result);
    
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValidation({
      ...inputValidation,
      [name]: value,
    });
    
   
  };
  

 const reset=()=>{
  setInputValidation({
    selectCategory: "",
    storeId: "",
    bannerName: "",
   
  })
  setFile("")
 }

  const [success,warning]=useToastMsg("Successfully added category banner","Somthing went wrong",reset)

  const Validation = () => {
    inputValidation.selectCategory === "" ||
      inputValidation.selectCategory === "--Banner Type--" ||
      inputValidation.bannerName === ""||
      files===""
      ? warning()
      : uploadImage();
  };



  const categoryItems = () => {
    return (
      <>
        {resultList.map((obj) => {
          return (
            <>
              <option key={obj.id} value={obj.id}>{obj.name}</option>
            </>
          );
        })}
      </>
    );
  };



  const uploadImage = async () => {
    
    
      const selectedIndex = storeResult.findIndex(
        (obj) => obj.id === inputValidation.storeId
      );
    const form_data = new FormData();
    form_data.append("file", files);
    let requestBody;
    location.state!=null?requestBody= {
      name: inputValidation.bannerName,
      option: inputValidation.selectCategory,
      optionValue: inputValidation.storeId,
      type: "CATEGORY",
      fileData: form_data,
      latitude:storeResult[selectedIndex].latitude,
      longitude: storeResult[selectedIndex].longitude,
      radius:storeResult[selectedIndex].radius,
      id:location.state.item.id
     
    }:requestBody={
    name: inputValidation.bannerName,
    option: inputValidation.selectCategory,
    optionValue: inputValidation.storeId,
    type: "CATEGORY",
    fileData: form_data,
    latitude:storeResult[selectedIndex].latitude,
    longitude: storeResult[selectedIndex].longitude,
    radius:storeResult[selectedIndex].radius,
    
   
  };
    console.log(inputValidation);
    console.log(requestBody);
    await dispatch(BannerImageUplaodRequest(requestBody)).then((response) => {
      
      consoleLog("response",response)
      response.data.responseCode===200 && success()    
      
    });
  };
  const handleImageInputChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded != null) {
      setFile(fileUploaded);
      // uploadImage(fileUploaded);
    }
  };

  const storeItems = () => {

    return (
      <>
      <label className=" mt-4">Store Name</label>
        <select
          value={inputValidation.storeId}
          name="storeId"
          onChange={handleChange}
          className="form-control"
        >
          <option>{"--select store--"}</option>
          {
           storeResult&&storeResult.length>0&& storeResult.map((obj) => {
              return(
               <option value={obj.id} key={obj.id}>{obj.name}</option>
              )
            })
          }
        </select>
      </>
    );
  };

  const locationStores=()=>{
    return (
      <>
      <label className=" mt-4">City</label>
        <select
          value={inputValidation.city}
          name="city"
          onChange={handleChange}
          className="form-control"
        >
          <option value={""}>{"--select City--"}</option>
          {
           availableLocation&&availableLocation.length>0&& availableLocation.map((obj) => {
              return(
               <option value={obj.city} key={obj.city}>{obj.city}</option>
              )
            })
          }
        </select>
      </>
    );
  }

  return (
    <>
      <ToastContainer />
      <div className="container-fluid dashboard d-flex flex-row justify-content-center">
        <div className="row m-5 add-homeBanner-width">
          <div className="col-12">
            <label>Category</label>
            <select
              className="form-control"
              name="selectCategory"
              value={inputValidation.selectCategory}
              onChange={handleChange}
            >
              <option>{"--Category--"}</option>
              {categoryItems()}
            </select>
            {(inputValidation.selectCategory === "" ||
              inputValidation.selectCategory === "--Banner Type--") && (
                <span className="text-danger">*Required</span>
              )}
          </div>
          <div className="col-12">

            {locationStores()}

          </div>
          <div className="col-12">

            {storeItems()}

          </div>

          <div className="col-12">
            <label className="mt-4">Banner Name</label>
            <input
              placeholder="Banner Name"
              name="bannerName"
              value={inputValidation.bannerName}
              onChange={handleChange}
              className="form-control"
            />
            {inputValidation.bannerName === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>
          <div className="col-12">
            <label className="mt-4">Banner Image</label>
            <input
              type="file"
              name="image"
              value={inputValidation.image}
              onChange={handleImageInputChange}
              className="form-control"
              style={{
                border: "1px solid #80df6d",
                height: 150,
                textDecoration: "underline",
              }}
              placeholder="Browse/Upload"
            />
            {files === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>
          <div className="col-12 text-center-new">
            <button className="btn  add-homeBanner-button-width mt-4" onClick={Validation}>
              Submit
            </button>
          </div>
          {
          consoleLog("feilds...", inputValidation)
          }
        </div>
      </div>
    </>
  );
}

export default AddCategoryBanner;
