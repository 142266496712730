import React, { useEffect, useState } from 'react'



import axios from 'axios';

import DbPaymentItem from './db-payment-item';
import { API_CONFIG, API_HEADERS } from '../../network/config/ApiConfig';
import { PAYMENTS_API_PATH } from '../../network/config/apiPaths';
import { consoleLog } from '../../utils/log-utils';
import { useLocation } from 'react-router-dom';

function DbPayment(props) {
  const[isLoading,setIsLoading]=useState(false)
  const[paymentList,setPaymentList]=useState([]);
  const[selectMonth,setMonth]=useState("");
  const location=useLocation();
  const id=location.state.db !=null ?location.state.db.id:"";
  consoleLog("dbId",id)
  const month=[
    {id:1,name:"JAN"},
    {id:2,name:"FEB"},
    {id:3,name:"MARCH"},
    {id:4,name:"APRIL"},
    {id:5,name:"MAY"},
    {id:6,name:"JUNE"},
    {id:7,name:"JULY"},
    {id:8,name:"AUG"},
    {id:9,name:"SEP"},
    {id:10,name:"OCT"},
    {id:11,name:"NOV"},
    {id:12,name:"DEC"},
  ]

  useEffect(()=>{
    paymentApiCall();
  },[])

  const paymentApiCall = async () => {
    let data = await axios.get(
      API_CONFIG.API_HOST +
        PAYMENTS_API_PATH.LIST_PATH +
        `?storeId=${props.id !== undefined ? props.id : ""}&dbId=${id}&type=db`,
      {
        headers: API_HEADERS,
      }
    );
    consoleLog("data", data.data.result);
    setPaymentList(data.data.result);
  };

  return (
    <>
     {/* <div className='row mb-3'>
        <div className='col-2'>
        <label>Month</label>
          <select value={selectMonth} className='form-control'>
            <option>{"--select month--"}</option>
            {
              month.map(item=>{
                return(
                  <option value={item.id}>{item.name}</option>
                )
              })
            }
          </select>
        </div>
        <div className='col-2'>

          <label>From</label>
          <input type="date" className='form-control'/>
            
          
        </div>
        <div className='col-2'>

          <label>To</label>
          <input type="date" className='form-control'/>
            
          
        </div>
       </div> */}
    {/* <div className="row mb-3 ">
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
             100 <br/> Orders Recieved
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      100 <br/> Orders Accepted
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      50 <br/> Orders Shipped
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      30 <br/> Orders Delivered
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      0 <br/> Orders Cancelled
      </div>
    </div>
  </div> */}
  <main className="container-fluid dashboard">
    <div className='row m-3'>
    <div className='col-12 mb-3'>
         <h4>DB Payments</h4>
      </div>
    <div className="col-12 card-1 h-scroll">
    <table className="table table-hover align-middle">
      <thead className="thead-dark">
        <tr>
          <th scope="col">Sno</th>
          <th scope="col">Duration</th>
         
          <th scope="col">D Boy Name</th>
          
          <th scope="col">Total Orders</th>
          <th scope="col">Total Amount</th>
          <th scope="col">DB Payment</th>
          <th scope="col">Date</th>
          <th scope="col">Transaction ID</th>
          <th scope="col">Status</th>
        </tr>
      </thead>
      
        <tbody>
          
          {
                  paymentList !==null ? paymentList.length>0&&
                  paymentList.map((item,i)=>{
                    return(
                      <>
                      <DbPaymentItem item={item} sno={i+1}/>
                      </>
                    )
                  }):<>No data found</>
                  }
        </tbody>
      
    </table>
  </div>
  </div>
  </main>
  </>
  )
}

export default DbPayment