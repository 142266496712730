import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";
import { loginAction } from "../network/store/action/UserResponseAction";
import { useDispatch, useSelector } from "react-redux";
import { changeStatus, loadingStatus } from "../redux/slice/loaderSlice";
import AppToast from "../components/appToast";
import { updateMessage } from "../redux/slice/toastSlice";
import AppLodingBar from "../components/loader";
import logo from "../assets/eatnewlogo.png";
import { isEmployee } from "../utils/userRoles";
import { consoleLog } from "../utils/log-utils";

/**
 *
 * @returns Login Component
 */
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector(loadingStatus);

  // user login form
  const [requestForm, setRequestForm] = useState({
    userName: "",
    password: "",
  });

  useEffect(()=>{
    dispatch(changeStatus(false));
  },[])

  const handleChanges = (e) => {
    setRequestForm({ ...requestForm, [e.target.name]: e.target.value });
  };

  const login = async () => {
    
    dispatch(changeStatus(true));
    let requestObject = {
      username: requestForm.userName,
      password: requestForm.password,
     
    };

    await dispatch(loginAction(requestObject, useDispatch)).then((response) => {
      dispatch(changeStatus(false));

      if (response.status) {
        consoleLog(" response.responseCode", response.responseCode)

       
        isEmployee()?dispatch(
          updateMessage({
            display: true,
            message: "No Access",
          })
        )
        :navigate("/dashboard");
      } else {
        dispatch(
          updateMessage({
            display: true,
            message: "Invalid username or Password",
          })
        );
      }
    });
  };

  return (
    <>
      {isLoading && <AppLodingBar />}
      {/* login-bg */}
      <div className="login-page">
        <div className="row">
          <div className="login card-1">
            <div>
              <h3 className="text-centers" style={{ color: "#fff" }}>
                <img src={logo} alt="" width="100%" />
              </h3>
            </div>
            <div>
              <div className="form-group">
                <label htmlFor="form2Example11">User Name</label>
                <input
                  type="email"
                  name="userName"
                  value={requestForm.userName}
                  onChange={(e) => handleChanges(e)}
                  id="form2Example11"
                  className="form-control"
                  placeholder="Enter email/Mobile number"
                />
              </div>
              <div className="form-group">
                <label htmlFor="form2Example22">Password</label>
                <input
                  type="password"
                  name="password"
                  value={requestForm.password}
                  onChange={(e) => handleChanges(e)}
                  id="form2Example22"
                  className="form-control"
                  placeholder="Enter Password"
                />
              </div>
            </div>
            <div>
              <button
                className="btn w-100 text-center"
                onClick={(e) => login(e)}
              >
                LOGIN
              </button>
            </div>
          </div>
        </div>
      </div>

      <AppToast />
    </>
  );
};

export default Login;
