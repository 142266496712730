import axios from "axios";
import { useEffect, useState } from "react";
import AutoComplete from "react-google-autocomplete";
import { useLocation, useNavigate } from "react-router-dom";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { consoleLog } from "../../utils/log-utils";
import { updateUserActon } from "../../network/store/action/UserResponseAction";
import { useDispatch } from "react-redux";


function UserEdit() {
    const navigate = useNavigate();
    const dispatch=useDispatch();
    const location = useLocation();
    const item = location.state !== null ? location.state.item : "";
    const [franchiseUsers, setFranchiseUsers] = useState([]);
    const [inputvalidation, setInputValidation] = useState({
        name: item.name,
        phone: item.phone,
        radius: item.radius,
        foShare: item.fo_share,
        city: item.city,
        fo_owner: item.franchiseOwner,
        adhar:"",
        license:"",
        pan:"",
        id:item.id
    });
    const labelStyle = {
        fontWeight: "bold",

    };
    const [city, setCity] = useState("");
    const [areaName, setAreaName] = useState("")
    const [latitude, setLatitude] = useState(item.latitude);
    const [longitude, setLongitude] = useState(item.longitude)

    useEffect(() => {
        FranchiseOwnerApi()
    }, [latitude])

   
    const updatePlaceInfo = (place) => {
        if (place && place.address_components != null) {
            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                switch (addressType) {
                    case "locality":
                        setAreaName(place.address_components[i].long_name)

                        break;
                    case "administrative_area_level_3":
                        setCity(place.address_components[i].long_name)


                        break;
                    // case "country":
                    //   setCountry(place.address_components[i].long_name);
                    //   break;
                }
            }
        }
        consoleLog("place..", place);

        setLatitude(place.geometry.location.lat())
        setLongitude(place.geometry.location.lng())
    };

    const handleChange = (e) => {
        
        setInputValidation(
            {
                ...inputvalidation,
                [e.target.name]: e.target.value 
            }
        )
    }

    const FranchiseOwnerApi = async () => {
        const data = await axios.get(API_CONFIG.API_HOST + `User/getNearbyUsers?lat=${latitude}&lng=${longitude}&roleId=9`, {
            headers: API_HEADERS,
        })
        consoleLog("in api..", data.data.result)
        setFranchiseUsers(data.data.result)
    }

    const SelectFranchiseOwner = () => {

        return (
            <select
                className="form-control"
                name="fo_owner"
                value={inputvalidation.fo_owner}
                onChange={handleChange}
            >
                <option value={""}>{"--select Franchise Owner--"}</option>
                {franchiseUsers && franchiseUsers.map((item, i) => {
                    return (
                        <option key={i} value={item.id}>
                            {item.name}
                        </option>
                    );
                })}
            </select>
        )
    }

    // const validation=()=>{
    //     (inputvalidation.adhar!=="" || inputvalidation.pan!==""||inputvalidation.license!=="")
    // }
    const updateUser = async (token) => {
       
    console.log("inputvalidation values",inputvalidation)
        await dispatch(updateUserActon(inputvalidation)).then((response) => {
          console.log(response);
          response.responseCode===200&&navigate(-1)
        });
      };
    return (
        <>
            <div
                style={{
                    position: "fixed",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    width: "100%",
                    height: "100%",
                    zIndex: "999",
                    overflow: "scroll",
                }}
            >
                <div className="d-flex align-item-center justify-content-center">
                    <div className="col-md-8">
                        <div className="container">
                            <div className="row mt-2">
                                <div className="col-md-5 card-1 p-5">
                                    <h4 className="text-center-new">Update User</h4>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label style={labelStyle}>Search City/Town</label>{" "}
                                            {/* {inputValidation.place_address} */}
                                            <AutoComplete
                                                className="form-control"
                                                style={{ width: "100%" }}
                                                name="city"
                                                options={{
                                                    types: ["geocode", "establishment"],
                                                }}
                                                onChange={(e) => handleChange(e)}
                                                value={inputvalidation.city}
                                                apiKey={"AIzaSyCB14naEHjDiVBFLEqhPBMUA9eqg_4yRdk"}
                                                onPlaceSelected={(place) => updatePlaceInfo(place)}
                                                placeholder="Enter city name"
                                            />

                                            <label>Name</label>
                                            <input
                                                type="text"
                                                onChange={handleChange}
                                                name="name"
                                                value={inputvalidation.name}
                                                className="form-control"

                                            />

                                            <label>Phone number</label>
                                            <input
                                                type="number"
                                                onChange={handleChange}
                                                name="phone"
                                                value={inputvalidation.phone}
                                                className="form-control"

                                            />

                                            {
                                                item.role_id === "7" ? <>
                                                    <label>Franchise owner</label>
                                                    {
                                                        SelectFranchiseOwner()
                                                    }
                                                </> : ""
                                            }

                                            {item.role_id === "9" ? <>
                                                <label>Franchise Percentage</label>
                                                <input
                                                    type="number"
                                                    onChange={handleChange}
                                                    name="foShare"
                                                    value={inputvalidation.foShare}

                                                    className="form-control"

                                                />
                                            </> : ""}

                                            {
                                                item.role_id === "9" ?
                                                    <>
                                                        <label>Radius</label>
                                                        <input
                                                            type="number"
                                                            onChange={handleChange}
                                                            name="radius"
                                                            value={inputvalidation.radius}


                                                            className="form-control"

                                                        />
                                                    </> : ""
                                            }
                                            {/* <label>Adhar Number</label>
                                            <input
                                                type="number"
                                                onChange={handleChange}
                                                name="adhar"
                                                value={inputvalidation.adhar}
                                                className="form-control"

                                            />
                                            <label>PAN Number</label>
                                            <input
                                                type="number"
                                                onChange={handleChange}
                                                name="pan"
                                                value={inputvalidation.pan}
                                                className="form-control"

                                            />

                                            <label>Driving License</label>
                                            <input
                                                type="number"
                                                onChange={handleChange}
                                                name="license"
                                                value={inputvalidation.license}
                                                className="form-control"

                                            /> */}

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    marginTop: 20,
                                                }}
                                            >
                                                <button
                                                    style={{ display: "flex", justifyContent: "center" }}
                                                    type="button"
                                                    className="btn btn-purple-bg"
                                                    onClick={updateUser}
                                                >
                                                    Submit
                                                </button>
                                                
                                                <button
                                                    className="btn btn-white-bg"
                                                    onClick={() => {
                                                        // dispatch(
                                                        //   updateFormInfo({
                                                        //     displayForm: false,
                                                        //     formData: {},
                                                        //   })
                                                        // );
                                                        navigate(-1)
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </>
    );
}

export default UserEdit;